import React from 'react'
import {NormalField} from 'reactstrap-form-fields'
import Button from './btn'

let Form = () => (
  <form name="Contact Form" method="POST" netlify data-netlify="true">
    <input type="hidden" name="form-name" value="Contact Form"/>
    <NormalField name="Nome e Cognome"/>
    <NormalField name="Email"/>
    <NormalField name="Numero di telefono"/>
    <NormalField name="Testo" type="textarea"/>
    <Button type="submit">Invia</Button>
  </form>
)

export default Form
